<template>
    <ODialog v-model:show="showDialog" disableResize size="lg" :title='$t("Give Feedback to Omega 365")' :disableMovable="false" disableFade :target="targetEl">
        <div :style="{ height: height }" class="bg-light">
            <iframe
                :src="src"
                class="w-100 h-100" 
                style="border:none;"
            ></iframe>
        </div>
    </ODialog>
</template> 
   
<script setup>
import { ref, inject, onMounted } from 'vue';
import { ODialog } from 'o365-ui-components';
import { getOrCreateDataObject } from 'o365-dataobject';

const targetEl = ref();
const showDialog = ref(false); 
const src = ref('');
const height = ref('490px');

const dsDatabaseSetup = getOrCreateDataObject({
    "id": `dsDatabaseSetup-Navbar`,
    "isStaticId": true,
    "viewName": "stbv_Database_Setup",
    "distinctRows": false,
    "uniqueTable": null,
    "allowUpdate": false,
    "allowInsert": false,
    "allowDelete": false,
    "appendData": false,
    "selectFirstRowOnLoad": true,
    "fields": [
        {
            "name": "FeedbackUrl",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
    ],
    "masterDataObject_ID": null,
    "masterDetailDefinition": [],
    "clientSideHandler": false,
    "maxRecords": 1,
    "dynamicLoading": false,
    "whereClause": null,
    "filterString": null,
    "disableAutoLoad": false,
    "optimisticLocking": false
})


const setOnOpen = inject('set-on-open', () => {});

setOnOpen(() => {
    showDialog.value = true;
    height.value = '535px';
});

onMounted(async () => {
    await dsDatabaseSetup.load();

    let referrer = "";
    try {
        referrer = encodeURIComponent(location.href);
    } catch {
        console.error("Failed to encodeUriComponent location.href: ", location.href);
    }

    let feedbackUrl = dsDatabaseSetup.current.FeedbackUrl ?? "https://omega.omega365.com/nt/feedback";
    if (referrer && new URL(feedbackUrl).searchParams.size > 0) {
        feedbackUrl += `&referrer=${referrer}`;
    } else if (referrer) {
        feedbackUrl += `?referrer=${referrer}`;
    }

    src.value = feedbackUrl;
})
</script>